import {Component, input} from '@angular/core'
import {RouterLink} from '@angular/router'
import {DOCUMENT_PATH} from '../../../application/types'
import {MatAnchor} from '@angular/material/button'

@Component({
  selector: 'spb-try-again',
  templateUrl: './try-again.component.html',
  styleUrl: './try-again.component.scss',
  imports: [
    RouterLink,
    MatAnchor
  ]
})
export class TryAgainComponent {
  public buttonText = input<string>()
  protected readonly DOCUMENT_PATH = DOCUMENT_PATH
}
