import {Component, EventEmitter, input, Output} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {IDocument} from '@sparbanken-syd/user-documents-backend'
import {DocumentNameComponent} from '../document-name/document-name.component'

@Component({
  selector: 'spb-selected',
  templateUrl: './selected.component.html',
  styleUrl: './selected.component.scss',
  imports: [MatIcon, DocumentNameComponent]
})
export class SelectedComponent {
  /**
   * If you want to delete pass a function.
   */
  @Output() remove: EventEmitter<string> = new EventEmitter<string>()

  public color = input<string>('#E0E0E0')

  public document = input.required<IDocument>()

  public removable = input<boolean>(true)


}
