import {Component} from '@angular/core'
import {DisclaimerComponent} from '../disclaimer/disclaimer.component'
import {DocumentComponent} from '../info/document.component'
import {RouterOutlet} from '@angular/router'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [DisclaimerComponent, DocumentComponent, RouterOutlet]
})
export class HomeComponent {
}
