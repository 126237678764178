<mat-card>
  <div>
    <p>Följande dokument har skickats till Sparbanken Syd</p>
    @for (document of documentService.movedDocuments$(); track document.id) {
      <spb-selected
        [removable]="false"
        color="#E0E0E0"
        [document]="document"></spb-selected>
    }
  </div>
  <spb-try-again buttonText="Klar"></spb-try-again>
</mat-card>

