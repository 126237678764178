import {inject, Injectable, signal} from '@angular/core'
import {Router} from '@angular/router'
import {HelperService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, map, Observable, of} from 'rxjs'
import {DOCUMENT_PATH, LOCAL_STORAGE, LOGIN_PATH} from '../application/types'
import {DocumentService} from './document.service'

const ACCESS_TOKEN_NAME = 'user-documents-token'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * The access token, primarily needed for the auth interceptor,
   * Note that signal does not work due to that user self is called
   * before the value is propagated.
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  /**
   * This is for the "admin", you can add additional
   */
  public isLoggedIn$ = signal<boolean>(false)
  private readonly injectedLocalStorage = inject(LOCAL_STORAGE)
  private readonly documentService = inject(DocumentService)
  private readonly router = inject(Router)

  public setToken(token: string | null): Observable<boolean> {
    const payLoad = HelperService.GetTokenPayload(token)
    if (payLoad && token) {
      this.accessToken$.next(token)
      this.isLoggedIn$.set(true)
      this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token)
      return this.documentService
        .getDocuments().pipe(map(() => true))
    }
    /**
     * If we are called with invalid tokens we reset all log in data
     * we do not explicitly LOG OUT!
     */
    this.reset()
    return of(true)
  }

  public newLogin(token: string): void {
    this.setToken(token).subscribe({
      next: () => this.router.navigate(
        [DOCUMENT_PATH, {outlets: {item: 'add'}}], {})
    })
  }

  public logout(): void {
    // Reset all values and navigates to login
    this.reset()
  }

  /**
   * Reset all admin values, and go to login. Do
   * not explicitly log out.
   */
  public reset(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.next(null)
    this.isLoggedIn$.set(false)
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    // Go to log-in
    this.router.navigate([LOGIN_PATH]).then()
  }

  /**
   * Sets the access token and returns an observable, used primarily
   * by the Bootstrap process
   */
  public checkStatus = (): Observable<any> => {
    this.isLoggedIn()
    return of({})
  }


  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const token = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    // The set token will throw if not a valid string
    try {
      this.setToken(token as string).subscribe()
    } catch {
      // Can happen and is quite common.
    }
  }
}
