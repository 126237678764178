import {Component, inject} from '@angular/core'
import {MatCard} from '@angular/material/card'
import {SelectedComponent} from '../../components/selected/selected.component'
import {DocumentService} from '../../services/document.service'
import {TryAgainComponent} from '../error/try-again/try-again.component'

@Component({
  selector: 'spb-done',
  templateUrl: './done.component.html',
  styleUrls: ['../big-icon.scss', './done.component.scss'],
  imports: [TryAgainComponent, MatCard, SelectedComponent]
})

export class DoneComponent {
  public documentService = inject(DocumentService)
}
